import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  EntityStatus,
  EntityDetailAction,
  WorkflowAction,
  MenuItemWorkflowAction,
  WorkflowActionModal,
  EntityStatusValue,
} from '@web-config-app/core';
import { isProduction } from '@leagueplatform/core';
import { UseEntityOperationsResult } from '@web-config-app/core-react';
import { draftErrorFilterKeywords } from '@web-config-app/validation';
import { useEntityFormError } from '../use-entity-form-error/use-entity-form-error.hook';

interface EntityWorkflowActionsProps {
  operations?: UseEntityOperationsResult;
  status?: EntityStatus;
  publishDangerously: boolean;
  isEntitySaved: boolean;
  entityConfigName: string;
  entityListPath: string;
  environmentName: string;
  isReadOnly?: boolean;
}

type EntityWorkflowActionsReturn = {
  primaryActionInterface: WorkflowAction;
  secondaryActionInterface: WorkflowAction;
  menuItems?: MenuItemWorkflowAction[];
};

export type UseEntityWorkflowActionsResult = ReturnType<
  typeof useEntityWorkflowActions
>;

/**
 * useEntityWorkflowActions - a hub that handles all things entity top bar workflow actions including label, enabled, tooltip, etc. for `EntityStatusValue`
 * @param operations - used in this hook where needed, like to determine which mutate function to call for the save button
 * @param status - used to determine different states and statuses of top bar things. Defaults to New when the status is undefined
 * @param isEntitySaved - based on if the entity is in sync with the data from the GET api call
 * @param entityConfigName - used to construct the tooltip of a menu item
 * @returns top bar workflows to be used by the header container
 */
export const useEntityWorkflowActions = ({
  operations,
  status,
  isEntitySaved,
  entityConfigName,
  entityListPath,
  environmentName,
  publishDangerously,
  isReadOnly,
}: EntityWorkflowActionsProps) => {
  const { formatMessage } = useIntl();

  const { validateEntityData } = useEntityFormError();

  const getEntityDetailsTopBarActionsByStatus = useMemo(() => {
    switch (status) {
      case EntityStatusValue.Draft:
        return {
          primaryActionInterface: {
            type: EntityDetailAction.Publish,
            label: formatMessage({
              id: 'PUBLISH',
            }),
            disabled: !(
              isEntitySaved && Boolean(operations?.publish.enableAction)
            ),
            modal: publishDangerously
              ? {
                  type: WorkflowActionModal.confirmation,
                  title: formatMessage(
                    { id: 'PUBLISH_DANGEROUSLY_MODAL_TITLE' },
                    { environment: environmentName },
                  ),
                  description: formatMessage(
                    {
                      id: 'PUBLISH_DANGEROUSLY_MODAL_DESCRIPTION',
                    },
                    {
                      entity: entityConfigName,
                      environment: environmentName,
                    },
                  ),
                  confirmCtaText: formatMessage(
                    {
                      id: 'PUBLISH_DANGEROUSLY_MODAL_PRIMARY_CTA',
                    },
                    {
                      environment: environmentName,
                      entity: entityConfigName,
                    },
                  ),
                  checkboxConfirmationLabel: formatMessage(
                    {
                      id: 'PUBLISH_DANGEROUSLY_MODAL_CHECKBOX',
                    },
                    {
                      environment: environmentName,
                      entity: entityConfigName,
                    },
                  ),
                  confirmCtaAction: operations?.publish.mutate,
                  onBeforeOpen: validateEntityData,
                }
              : undefined,
            display: true,
            action: () => {
              if (validateEntityData()) {
                operations?.publish.mutate();
              }
            },
          },
          secondaryActionInterface: {
            type: EntityDetailAction.Update,
            label: formatMessage({
              id: 'SAVE_CHANGES',
            }),
            disabled: isEntitySaved,
            display: Boolean(operations?.update.enableAction),
            action: () => {
              // TODO:: Remove with https://everlong.atlassian.net/browse/CACT-1533
              if (isProduction()) {
                operations?.update.mutate();
              } else if (
                validateEntityData({
                  filteredErrorKeywords: draftErrorFilterKeywords,
                })
              ) {
                operations?.update.mutate();
              }
            },
          },
          menuItems: [
            {
              type: EntityDetailAction.DuplicateAsDraft,
              label: formatMessage({ id: 'DUPLICATE_AS_DRAFT' }),
              tooltip: formatMessage(
                { id: 'SAVE_BEFORE_YOU_DUPLICATE' },
                { entity: entityConfigName },
              ),
              disabled: !isEntitySaved,
              display: Boolean(operations?.duplicateAsDraft.enableAction),
              // only allow the user to action on the duplicateAsDraft menu item when the entity is saved
              action: isEntitySaved
                ? operations?.duplicateAsDraft.mutate
                : null,
            },
            {
              type: EntityDetailAction.Archive,
              label: formatMessage({ id: 'ARCHIVE' }),
              tooltip: formatMessage(
                { id: 'UNPUBLISH_BEFORE_YOU_ARCHIVE' },
                { entity: entityConfigName },
              ),
              disabled: false,
              display: Boolean(operations?.archive?.enableAction),
              action: operations?.archive?.mutate,
            },
          ].filter((item) => item.display),
        };
      case EntityStatusValue.Published:
        return {
          primaryActionInterface: {
            type: EntityDetailAction.Republish,
            label: formatMessage({
              id: 'PUBLISH_CHANGES',
            }),
            // Disable publishing/updating when the entity is readonly
            display: Boolean(operations?.republish.enableAction) && !isReadOnly,
            disabled: isEntitySaved,
            action: () => {
              if (validateEntityData()) {
                operations?.republish.mutate();
              }
            },
          },
          secondaryActionInterface: {
            type: EntityDetailAction.Update,
            label: formatMessage({
              id: 'SAVE_CHANGES',
            }),
            display: false,
          },
          menuItems: [
            {
              type: EntityDetailAction.Unpublish,
              label: formatMessage({ id: 'UNPUBLISH' }),
              disabled: false,
              display: Boolean(operations?.unpublish.enableAction),
              modal: {
                type: WorkflowActionModal.confirmation,
                confirmCtaAction: operations?.unpublish?.mutate,
                title: formatMessage(
                  { id: 'UNPUBLISH_MODAL_TITLE' },
                  { entity: entityConfigName, env: environmentName },
                ),
                description: formatMessage(
                  {
                    id: 'UNPUBLISH_MODAL_DESCRIPTION',
                  },
                  { entity: entityConfigName },
                ),
                confirmCtaText: formatMessage(
                  {
                    id: 'UNPUBLISH_PRIMARY_CTA',
                  },
                  { env: environmentName },
                ),
              },
            },
            {
              type: EntityDetailAction.DuplicateAsDraft,
              label: formatMessage({ id: 'DUPLICATE_AS_DRAFT' }),
              tooltip: formatMessage(
                { id: 'SAVE_BEFORE_YOU_DUPLICATE' },
                { entity: entityConfigName },
              ),
              disabled: !isEntitySaved,
              display: Boolean(operations?.duplicateAsDraft.enableAction),
              // only allow the user to action on the duplicateAsDraft menu item when the entity is saved
              action: isEntitySaved
                ? operations?.duplicateAsDraft.mutate
                : null,
            },
            {
              type: EntityDetailAction.Archive,
              label: formatMessage({ id: 'ARCHIVE' }),
              tooltip: formatMessage(
                { id: 'UNPUBLISH_BEFORE_YOU_ARCHIVE' },
                { entity: entityConfigName },
              ),
              disabled: true,
              display: Boolean(operations?.archive?.enableAction),
            },
          ].filter((item) => item.display),
        };
      case EntityStatusValue.Archived:
        return {
          primaryActionInterface: {
            type: EntityDetailAction.Publish,
            label: formatMessage({
              id: 'PUBLISH',
            }),
            display: false,
          },
          secondaryActionInterface: {
            type: EntityDetailAction.Unarchive,
            label: formatMessage({
              id: 'UN_ARCHIVE',
            }),
            display: Boolean(operations?.unarchive?.enableAction),
            action: operations?.unarchive?.mutate,
          },
        };
      case EntityStatusValue.New:
      default:
        return {
          primaryActionInterface: {
            type: EntityDetailAction.Publish,
            label: formatMessage({
              id: 'PUBLISH',
            }),
            display: false,
          },
          secondaryActionInterface: {
            type: EntityDetailAction.Create,
            label: formatMessage({
              id: 'SAVE_DRAFT',
            }),
            display: true,
            action: () => {
              // TODO:: Remove with https://everlong.atlassian.net/browse/CACT-1533
              if (isProduction()) {
                operations?.create.mutate();
              } else if (
                validateEntityData({
                  filteredErrorKeywords: draftErrorFilterKeywords,
                })
              ) {
                operations?.create.mutate();
              }
            },
          },
          menuItems: [
            {
              type: EntityDetailAction.ExitAndDiscardChanges,
              label: formatMessage({ id: 'EXIT_AND_DISCARD_CHANGES' }),
              disabled: false,
              display: !isEntitySaved,
              modal: {
                type: WorkflowActionModal.confirmation,
                confirmCtaAction: entityListPath,
                title: formatMessage(
                  { id: 'EXIT_ENTITY_DETAIL_TITLE' },
                  { entity: entityConfigName },
                ),
                description: formatMessage({
                  id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
                }),
                confirmCtaText: formatMessage({
                  id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
                }),
              },
            },
          ].filter((item) => item.display),
        };
    }
    // ESLint suggests the wrong set of dependencies such as operations?.archive even though we are not directly calling
    // operations?.archive. Therefore, disable.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entityConfigName,
    formatMessage,
    isEntitySaved,
    isReadOnly,
    operations?.archive?.enableAction,
    operations?.archive?.mutate,
    operations?.duplicateAsDraft?.enableAction,
    operations?.duplicateAsDraft?.mutate,
    operations?.unarchive?.enableAction,
    operations?.unarchive?.mutate,
    operations?.unpublish?.enableAction,
    operations?.unpublish?.mutate,
    status,
    validateEntityData,
    entityListPath,
  ]);

  const actions: EntityWorkflowActionsReturn =
    getEntityDetailsTopBarActionsByStatus;
  return actions;
};
