import {
  Entity,
  EndpointPathParameter,
  EndpointQueryParameter,
  EntityDetail,
  EntityConfigEndpoint,
  Category,
} from '@web-config-app/core';

import { mockSimpleSchema } from './mock-schemas';

const mockEndpointPath = `v1/config-cactuses`;
const mockEndpointWithIdPath = `v1/config-cactuses/{cactusId}`;

const mockPathParams: EndpointPathParameter[] = [
  {
    name: 'cactusId',
    in: 'path',
    schema: {
      type: 'string',
    },
    required: true,
  },
];

const mockQueryParams: EndpointQueryParameter[] = [
  {
    name: 'arms[count]',
    in: 'query',
    schema: {
      type: 'string',
    },
    required: false,
  },
  {
    name: 'spikes[count]',
    in: 'query',
    schema: {
      type: 'string',
    },
    required: false,
  },
];

const includeParam: EndpointQueryParameter = {
  name: 'include',
  in: 'query',
  schema: {
    type: 'string',
  },
  required: false,
};

export const mockListEndpoint: EntityConfigEndpoint = {
  path: mockEndpointPath,
  method: 'GET',
  pathParameters: mockPathParams,
  queryParameters: mockQueryParams,
};

const mockGetEndpoint: EntityConfigEndpoint = {
  path: mockEndpointWithIdPath,
  method: 'GET',
  pathParameters: mockPathParams,
  queryParameters: [includeParam],
};

const mockCreateEndpoint: EntityConfigEndpoint = {
  path: mockEndpointPath,
  method: 'POST',
  pathParameters: mockPathParams,
  queryParameters: mockQueryParams,
};

const mockDuplicateEndpoint: EntityConfigEndpoint = {
  path: mockEndpointPath,
  method: 'POST',
};

const mockUpdateEndpoint: EntityConfigEndpoint = {
  path: mockEndpointWithIdPath,
  method: 'POST',
  pathParameters: mockPathParams,
  queryParameters: mockQueryParams,
};

export const mockEntity: Entity = {
  id: 'cactuses',
  name: 'Cactuses',
  type: 'config-cactus',
  category: Category.Default,
  nameTranslationKey: 'CACTUSES',
  schema: mockSimpleSchema,
  endpoints: {
    list: mockListEndpoint,
    create: mockCreateEndpoint,
    get: mockGetEndpoint,
    update: mockUpdateEndpoint,
    duplicateAsDraft: mockDuplicateEndpoint,
  },
};

export const mockEntityDetail: EntityDetail = {
  id: '123456789012312455',
  type: 'campaign',
  attributes: {
    entityMetadata: {
      status: 'draft',
      name: 'Fun Campaign',
      readOnly: true,
    },
    metaData: {
      internalName: 'Fun Campaign',
    },
  },
};
